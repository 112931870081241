<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-tabs
                        v-model="financeDataOverviewCurrentTab"
                        color="success"
                        background-color="grey lighten-2"
                        slider-color="appic-green"
                    >
                        <v-tab key="bank-accounts" @click="changeExportParams('bank-accounts')">{{ $t('message.bankAccounts') }}</v-tab>
                        <v-tab key="payment-terms" @click="changeExportParams('payment-terms')">{{ $t('message.paymentTerms') }}</v-tab>
                        <v-tab key="exchange-rates" @click="changeExportParams('exchange-rates')">{{ $t('message.exchangeRates') }}</v-tab>
                        <v-tab key="currencies" @click="changeExportParams('currencies')">{{ $t('message.currencies') }}</v-tab>
                        <v-tab key="warehouse-pricing-variables" @click="changeExportParams('warehouse-pricing-variables')">{{ $t('message.warehousePricingVariables') }}</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="financeDataOverviewCurrentTab">
                        <v-tab-item key="bank-accounts-list">
                            <v-card flat>
                                <BankAccountsListing
                                    :add-dialog="openAddBankAccountDialog"
                                    :key="bankAccountsListKey"
                                    @add-dialog-closed="addBankAccountDialogClosed"
                                    @reload="reloadBankAccountsList"
                                />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="payment-terms-list">
                            <v-card flat>
                                <PaymentTermsListing
                                    :add-dialog="openAddPaymentTermDialog"
                                    :key="paymentTermsListKey"
                                    @add-dialog-closed="addPaymentTermDialogClosed"
                                    @reload="reloadPaymentTermsList"
                                />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="exchange-rates-list">
                            <v-card flat>
                                <ExchangeRatesListing
                                    :add-dialog="openAddExchangeRateDialog"
                                    :key="exchangeRatesListKey"
                                    @add-dialog-closed="addExchangeRateDialogClosed"
                                    @reload="reloadExchangeRatesList"
                                />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="currencies-list">
                            <v-card flat>
                                <CurrenciesListing
                                    :key="currenciesListKey"
                                    @reload="reloadCurrenciesList"
                                />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="warehouse-pricing-variables-list">
                            <v-card flat>
                                <WarehousePricingVariables
                                    :key="warehousePricingVariablesKey"
                                    @reload="reloadWarehousePricingVariables"
                                />
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import WarehousePricingVariables from "../../../components/Appic/WarehousePricingVariables.vue";

const BankAccountsListing = () => import("Components/Appic/BankAccountsListing");
const ExportTable = () => import("Components/Appic/ExportTable");
const PaymentTermsListing = () => import("Components/Appic/PaymentTermsListing");
const ExchangeRatesListing = () => import("Components/Appic/ExchangeRatesListing");
const CurrenciesListing = () => import("Components/Appic/CurrenciesListing");

export default {
    name: "FinanceDataOverview",
    components: {WarehousePricingVariables, CurrenciesListing, ExchangeRatesListing, PaymentTermsListing, BankAccountsListing, ExportTable },
    title: '',
    data() {
        return {
            bankAccountsListKey: 0,
            currenciesListKey: 0,
            loader: false,
            active: null,
            colsToDelete: {
                start_col: 10,
                ncols: 1
            },
            exchangeRatesListKey: 0,
            fileName: 'bank-accounts',
            hasAddFunction: true,
            document: 'bank-accounts',
            tableId: 'bankAccountsTable',
            openAddBankAccountDialog: false,
            openAddExchangeRateDialog: false,
            openAddPaymentTermDialog: false,
            paymentTermsListKey: 0,
            warehousePricingVariablesKey: 0
        }
    },
    computed: {
        ...mapFields('runtime',{
            financeDataOverviewCurrentTab: 'financeDataOverviewCurrentTab'
        })
    },
    methods: {
        addBankAccountDialogClosed () {
            this.openAddBankAccountDialog = false
        },
        addExchangeRateDialogClosed () {
            this.openAddExchangeRateDialog = false
        },
        addPaymentTermDialogClosed (){
            this.openAddPaymentTermDialog = false
        },
        changeExportParams(itemType){
            switch (itemType) {
                case 'bank-accounts':
                    this.colsToDelete = {start_col: 0, ncols: 1}
                    this.fileName = 'banks'
                    this.hasAddFunction = true
                    this.document = 'bank-accounts'
                    this.tableId = 'bankAccountsTable'
                    break
                case 'exchange-rates':
                    this.colsToDelete = {start_col: 0, ncols: 1}
                    this.fileName = 'exchange-rates'
                    this.hasAddFunction = true
                    this.document = 'exchange-rates'
                    this.tableId = 'exchangeRatesTable'
                    break
                case 'payment-terms':
                    this.colsToDelete = {start_col: 0, ncols: 1}
                    this.fileName = 'payment-terms'
                    this.hasAddFunction = true
                    this.document = 'payment-terms'
                    this.tableId = 'paymentTermsTable'
                    break
            }
        },
        newItem (itemType) {
            switch(itemType){
                case 'bank-accounts':
                    this.openAddBankAccountDialog = true
                    break
                case 'exchange-rates':
                    this.openAddExchangeRateDialog = true
                    break
                case 'payment-terms':
                    this.openAddPaymentTermDialog = true
                    break
            }
        },
        reloadBankAccountsList() {
            this.bankAccountsListKey = Math.floor(Math.random() * 100)
        },
        reloadExchangeRatesList() {
            this.exchangeRatesListKey = Math.floor(Math.random() * 100)
        },
        reloadPaymentTermsList() {
            this.paymentTermsListKey = Math.floor(Math.random() * 100)
        },
        reloadCurrenciesList() {
            this.currenciesListKey = Math.floor(Math.random() * 100)
        },
        reloadWarehousePricingVariables() {
            this.warehousePricingVariablesKey = Math.floor(Math.random() * 100)
        }
    },
    mounted() {
        switch(this.financeDataOverviewCurrentTab){
            case 0:
                this.changeExportParams('bank-accounts')
                break
            case 1:
                this.changeExportParams('payment-terms')
                break
            case 2:
                this.changeExportParams('exchange-rates')
                break
        }
    }
}
</script>

<style scoped>
.v-tab {
    text-transform: none!important;
}
</style>